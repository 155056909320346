import React from "react"
import styled from "styled-components"
import PageDivider from "../PageDivider"

import NexplanonLogo from '../../assets/nexplanon-logo.svg';
import SupportButton from '../../assets/support-button.png';
import ListPipe from '../../assets/list-pipe.png';

const SecondaryCardRow = () => (
    <>
    <CardWrapper>
    <h1>Other Organon Support Programs</h1>
        <CardContainer>
            <Card>
                <CardContent>
                    <LogoContainer>
                        <NexplanonLogo/>
                        <a target="_blank" href="https://www.organon-cscn.com/">
                            <SupportContainer>
                            <img src={SupportButton} />
                            </SupportContainer>
                        </a>
                    </LogoContainer>
                </CardContent>

                <LinkContainer>
                    <LinkText><a target="_blank" href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_pi.pdf">PRESCRIBING INFORMATION</a><span></span></LinkText>
                    <LinkText><a target="_blank" href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_ppi.pdf">PATIENT INFORMATION</a></LinkText>
                </LinkContainer>
            </Card>
        </CardContainer>
    </CardWrapper>

    <PageDivider/>
    </>
)

const CardWrapper = styled.div`
    width: 100%;
    padding: 1em 0;

    h1 {
        margin-bottom: 1rem;
    }
`

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: -15px;
    @media (max-width: 415px) {
        flex-direction: column;
    }
`

const Card = styled.div`
    width: 440px;
    margin: 15px;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);

    @media (max-width: 415px) {
        width: initial;
    }
`

const CardContent = styled.div`
    padding: 10px 15px 5px;
`

const LogoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 0.5rem;

    svg {
        width: auto;
        height: 100%;

        @media (max-width: 415px) {
            max-width: 50%;
        }
    }
`

const LinkContainer = styled.div`
    height: 30px;
    background: var(--light-gray);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`

const LinkText = styled.div`
    font-family: Montserrat;
    font-size: 10px;
    position: relative;

    a { 
        color: var(--black);
    }

    span {
        &:after {
            content: '';
            background-image: url(${ListPipe});
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 23px;
            position: absolute;
            top: 2px;
            left: 175px;

            @media (max-width: 415px) {
                top: 0;
                left: 161px;
            }
        }
    }
`

const SupportContainer = styled.div`
    img {
        width: 140px;
        margin-bottom: 0;
    }
`

export default SecondaryCardRow