import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
const NODE_ENV = process.env;

function SEO({ description, lang, meta, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `google-site-verification`,
                content: `YIv_HNxMc-p2XV32vStIkhksoY4BRNpelm__c_jZ8FI`
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >

          {/* STAGE CCPA SCRIPT  */}
            {/* <!-- OneTrust Cookies Consent Notice start for organon-access-program.mzr.egplusww.com --> */}
              {/* <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script={`878754ec-4844-47d9-9938-5557fb38c955`} ></script>
              <script type="text/javascript" dangerouslySetInnerHTML={{__html: `function OptanonWrapper() { }`}}></script> */}
          {/* <!-- OneTrust Cookies Consent Notice end for organon-access-program.mzr.egplusww.com --> */}

          {/* PROD CCPA SCRIPT */}
            {/* <!-- OneTrust Cookies Consent Notice start for organonaccessprogram.com --> */}
              <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script={`878754ec-4844-47d9-9938-5557fb38c955`} ></script>
              <script type="text/javascript" dangerouslySetInnerHTML={{__html: `function OptanonWrapper() { }`}}></script>
            {/* <!-- OneTrust Cookies Consent Notice end for organonaccessprogram.com --> */}

            {/* <!-- Google Tag Manager --> */}
            <script type="text/javascript">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5LVTRH3');`}</script>
            {/* <!-- End Google Tag Manager --> */}
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
